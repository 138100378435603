<template>
  <div class="container user-setting-group">
    <div class="page-header mb-1">
      <strong>เปิด/ปิด หวย</strong>
    </div>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="group in groups" :key="group._id" class="nav-item">
            <a class="nav-link" :class="[{'active':tabActiveGroup===group._id}]" href="#" target="_self" @click="tabActiveGroupId=group._id">{{ group.groupTitle }}</a>
          </li>
        </ul>
      </div>
      <div>
        <table class="table-accounts table table-hover mb-0">
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>ชื่อใช้งาน</th>
              <th>ระดับ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id">
              <td>{{ index+1 }}</td>
              <td>{{ item.user.username }}</td>
              <td>{{ item.level.levelName }}</td>
              <td>
                <b-form-checkbox v-model="item.groups[tabActiveGroup].isAvailable" name="check-button" switch @change="toggleChange(item)" :class="[item.groups[tabActiveGroup].isAvailable ? 'text-success' : 'text-danger']">
                  {{ item.groups[tabActiveGroup].isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td class="text-center" colspan="4">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import AccountService from "@/services/accountService"
import Swal from 'sweetalert2'

export default {
  name: 'SettingGroup',
  data() {
    return {
      tabActiveGroupId: null,
      data: []
    }
  },
  computed: {
    groups() {
      // return this.$store.state.marketGroups
      return this.$store.state.marketGroups.filter(group=>group.status==='Active' /*&& group.groupType==='single'*/)
    },
    tabActiveGroup() {
      return this.tabActiveGroupId
    },
    items() {
      return this.data
    }
  },
  methods: {
    loadAccounts() {
      AccountService.getAccountSettingGroup()
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.data = response.data
          if(!this.tabActiveGroupId) {
            this.tabActiveGroupId = this.groups[0]?._id
          }
        }
      })
    },
    username(users) {
      if(users.length > 0) {
        return users[0].username
      }else{
        return '-'
      }
    },
    level(item) {
      return item?.levels[0]?.level_name ?? '-'
    },
    toggleChange(account) {
      const updateData = {
        accountId: account._id,
        groupId: this.tabActiveGroup,
        groupTitle: account.groups[this.tabActiveGroup].groupTitle,
        isAvailable: account.groups[this.tabActiveGroup].isAvailable
      }
      AccountService.updateAccountSettingGroup(updateData)
      .then((response)=>{
        if(!response.success) {
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        console.log(e.message)
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadAccounts()
      })
    }
  },
  created() {
    console.log('this.groups', this.groups)
    this.tabActiveGroupId = this.groups[0]?._id

    if(this.groups.length === 0) {
      this.$store.dispatch('reloadMarketGroups')
    }
  },
  mounted() {
    this.loadAccounts()
  }
}
</script>
<style lang="scss" scoped>
.user-setting-group {
  table {
    th {
      border-top: 0;
    }
    td {
      vertical-align: middle;
    }
  }
}
</style>
